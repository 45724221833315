import React from "react";

import { Layout, SEO } from "../components";

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="404: Not Found" />
        <div>
          <h1>Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
